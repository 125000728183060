<template>
    <div class="checkstand">
        <div class="head">
            <div class="userInfo">
                <van-image class="my-image" round fit="cover" :src="getImg(userInfo.picHeadUrl)" alt=""></van-image>
                <div class="phone">{{userInfo.mobile | mobile}}</div>
            </div>
            
        </div>
        <div class="content">
                <div class="title">
                    {{title}}
                </div>
                <div class="money">
                    ￥{{payAmount | money}}
                </div>
                <div class="tip">
                    <div class="tip-title">
                        权益须知
                    </div>
                    <div class="text">
                        <p>1.至少提前三天预约，以提起支付教练费为准。（关注微信公众号和小程序）</p>
                        <p>2.会员课程请假或取消请在课程前12小时以上，否则会扣除当天课时，不限课时将扣除教练费用。</p>
                    </div>
                </div>
                <my-btn :btnStr="'确认付款'" @onSubmit="onSubmit" />
            </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgWx: require('@/assets/img/wx.png'),
            selectMoney: '',
            payType: [],
            dataInvoke: {},
            payAmount: '',
            userInfo: {},
            const: 1,
            headUrl: null,
            title: '',
        }
    },
    filters: {
        mobile(val) {
            let str
            val = '' + val
            if(val !== '') {
                str = val.substr(0, 3) + '****' + val.substr(7)
            }
            return str
        },
        money(val) {
            let str
            if(val) {
                str = (val-0).toFixed(2)
            }
            return str
        }
    },
    methods: {
        onSubmit() {
            //console.log(123)
            //this.onBridgeReady()
            this.$api.post('member/bill/payRecord/startPay', {
                openId: this.$route.query.data,
                orderId: this.$route.query.orderId,
                orderType: this.$route.query.orderType
            }).then(res=>{
                console.log(res)
                this.dataInvoke = res
                if(!res.settle){
                    this.onBridgeReady() 
                }else{
                    this.$help.success('购买成功!')
                    this.goBack()
                }
            })
        },
        goBack() {
            if(this.$route.query.orderType === 'bill_member_recharge'){
                this.$router.push('/center/charge')
            }
            if(this.$route.query.orderType === 'bill_card_order'){
                this.$router.push('/course')
            }
            if(this.$route.query.orderType === 'bill_shopping_order'){
                this.$router.push('/mall')
            }
            if(this.$route.query.orderType === 'bill_curriculum_order'){
                this.$router.push('/horsefarm')
            }
        },
        onBridgeReady() {
            // 下面这些参数都是通过后端传回来的
            this.const++
            const that = this
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest',
                {
                    appId: this.dataInvoke.appId,     //公众号名称，由商户传入
                    timeStamp: this.dataInvoke.timeStamp,         //时间戳，自1970年以来的秒数
                    nonceStr: this.dataInvoke.nonceStr, //随机串
                    package: this.dataInvoke.packageValue,
                    signType: this.dataInvoke.signType,         //微信签名方式：
                    paySign: this.dataInvoke.paySign
                },
                function(res) {
                    if (res.err_msg == 'get_brand_wcpay_request:ok') {
                        //Toast('支付成功');
                        that.$help.success('支付成功')
                        setTimeout(()=>{
                            that.goBack()
                        },500)
                    } else {
                        //Toast('取消支付');
                        //that.$help.error('取消支付')
                        setTimeout(()=>{
                            that.goBack()
                        },500)
                    }
                },
            );
            if (typeof window.WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }
            }
            //  else {
            //     if(this.const <= 4){
            //         this.onBridgeReady();
            //     }
            // }
        },
        getOrderInfo(id) {
            this.$help.loading()
            this.$api.post('member/bill/memberRecharge/memberRecharge-show', {id: id}).then(res=>{
                this.payAmount = res.payAmount
                this.title = '余额充值'
            })
            .finally(this.$help.loaded)
        },
        getCardOrderInfo(id){
            this.$help.loading()
            this.$api.post('member/bill/cardOrder/cardOrder-show', {id: id}).then(res=>{
                this.payAmount = res.payAmount
                this.title = res.invName
            })
            .finally(this.$help.loaded)
        },
        getShopOrderInfo(id){
            this.$help.loading()
            this.$api.post('member/bill/shoppingOrder/shoppingOrder-show', {id: id}).then(res=>{
                this.payAmount = res.payAmount
                this.title = res.cardName
            })
            .finally(this.$help.loaded)
        },
        getCourseInfo(id){
            this.$help.loading()
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-show', {id: id}).then(res=>{
                this.payAmount = res.payAmount
                this.title = res.curriculumName
            })
            .finally(this.$help.loaded)
        },
        getMemberUser() { 
            this.$help.loading()
            this.$store.dispatch('getMemberUser').then(() => {
                this.userInfo = this.$store.state.memberUser
            })
            .finally(this.$help.loaded)
        },
        
    },
    created() {
        this.getMemberUser()
    },
    mounted() {
        //console.log(this.$route.query)
        if(this.$route.query.orderType === 'bill_member_recharge'){
            this.getOrderInfo(this.$route.query.orderId)
        }
        if(this.$route.query.orderType === 'bill_card_order'){
            this.getCardOrderInfo(this.$route.query.orderId)
        }
        if(this.$route.query.orderType === 'bill_shopping_order'){
            this.getShopOrderInfo(this.$route.query.orderId)
        }
        if(this.$route.query.orderType === 'bill_curriculum_order'){
            this.getCourseInfo(this.$route.query.orderId)
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .checkstand{
        .head{
            height: 40vh;
            background-color: $main;
            display: flex;
            justify-content: center;
            align-items: center;
            .userInfo{
                text-align: center;
                .my-image{
                    height: 60px;
                    width: 60px;
                }
                .phone{
                    color: #fff;
                }
            }
        }
        .content{
            height: 50%;
            // 
            .title{
                margin-top: 10px;
                padding: 10px 0;
                text-align: center;
            }
            .money{
                padding: 10px 20px;
                text-align: center;
                font-size: 28px;
                font-weight: bold;
                font-family: Arial, Helvetica, sans-serif;
                
            }
            .tip{
                margin: 20px;
                padding: 15px;
                border-radius: 19px;
                color: #999;
                background-color: #fff;
                &-title{
                    text-align: center;
                    padding-bottom: 10px;
                }
                .text{
                    p{
                        font-size: 12px;
                        font-family: Arial, Helvetica, sans-serif;
                    } 
                }
            }
            .van-radio-group {
                padding-bottom: 64px;
            }
            .van-cell{
                align-items: center;
                &__title{
                    span{
                        padding-left: 10px;
                        font-size: 16px;
                    }
                }
                .balance{
                    padding: 0 10px;
                }
                .van-icon-paid{
                    color: $main;
                    padding-right: 5px;
                }
                .van-radio__icon--checked{
                    .van-icon{
                        color: #fff;
                        background-color: $main;
                        border-color: $main;
                    }
                }
            }
        }
    }

</style>